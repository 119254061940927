import React from 'react';
import DataList from '../DataList';
import ApiConfigFactory from '../../configurations/ApiConfigFactory';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../styles/LabTestRequestList.css';
import { useAuth } from '../../hooks/useAuth';

const LabTestRequestList = ({patient, viewName}) => {
  console.log("View: ",viewName);
  const location = useLocation();
  const { hasPermission } = useAuth();
  // Extract the patient object from location state
  //const { patient } = location.state || {};
  // Derive patient id from the patient object, if available
  const id = patient ? patient.patient_id : undefined;
  // Set a title using patient details if available
  const title = patient
    ? `Viewing Lab Requests for ${patient.patient_first_name} ${patient.patient_last_name} - ${patient.patient_number}`
    : 'Lab Test Requests';

  // Initialize apiConfig using ApiConfigFactory with the patient id (if available)
  const apiConfig = new ApiConfigFactory('lab/lab-requests', title, 'patient_id', id);
  apiConfig.defaultSortAsc = false;
  apiConfig.defaultSortFieldId = 'id';
  apiConfig.path = '/lab-requests';
  apiConfig.allowAdd = false;
  apiConfig.allowDelete = false;
  if(viewName!='PatientCard') {
    apiConfig.systemFilters= [{ name: 'request_type', value: 'Laboratory'},];
  }
    // Conditionally add bulkAction if the user has permission.
    if (hasPermission('/lab-requests/mark-paid', 'View')) {
      apiConfig.addBulkAction({ name: 'Mark as Paid', endpoint: '/hospital-portal/api/lab/lab-requests/markRequestPaid' });
    }
  // Configure fields, columns, and other properties
  apiConfig
    .addField({
      name: 'id',
      label: 'Request ID',
      type: 'text',
      identifier: true,
      disabled: true,
      tab: 'Main'
    })
    .addField({
      name: 'patient_id',
      label: 'Patient ID',
      type: 'text',
      required: true,
      disabled: true,
      tab: 'Main',
      defaultValue: id
    })
    .addField({
      name: 'requested_by',
      label: 'Requester',
      type: 'text',
      disabled: true,
      tab: 'Main'
    })
    .addField({
      name: 'status',
      label: 'Status',
      type: 'dropdown',
      required: true,
      tab: 'Main',
      disabled: true
    })
    .addField({
      name: 'invoiced',
      label: 'Invoiced?',
      type: 'checkbox',
      required: false,
      tab: 'Admin'
    })
    .addField({
      name: 'createdAt',
      label: 'Request Date',
      type: 'datetime',
      disabled: true,
      tab: 'Main'
    })
    .addField({
      name: 'updatedBy',
      label: 'Updated By',
      type: 'text',
      disabled: true,
      tab: 'Admin'
    })
    .addStaticDropdownField({
      name: 'status',
      options: [
        { id: 'unpaid', name: 'Unpaid' },
        { id: 'paid', name: 'Paid' },
        { id: 'complete', name: 'Complete' }
      ]
    })
    .addTab({ name: 'Main', roles: 'all', default: true })
    .addTab({ name: 'Admin', roles: 'Admin' })
    .addColumn({
      id: 'id',
      name: 'ID',
      selector: (row) => row.id,
      type: 'text',
      identifier: true,
       width: '75px'
    });


    apiConfig
      .addColumn({
        name: 'Patient Number',
        selector: (row) => row.Patient.patient_number,
        sortable: true,
        filterable: true,
        filterSelector: 'Patient.patient_number',
        width: '100px',
        omit: viewName=='PatientCard'
      })
      .addColumn({
        name: 'Patient Name',
        selector: (row) => `${row.Patient?.patient_first_name} ${row.Patient?.patient_last_name}`,
        sortable: true,
        width: '150px',
        omit:viewName=='PatientCard'
      });

    apiConfig
      .addColumn ({
        name: 'Type',
        selector: (row) => row.request_type,
        sortable: true,
        filterable: true,
        filterSelector: 'request_type',
        width: '100px',
        omit:viewName!='PatientCard'
      })
 

  apiConfig
    .addColumn({
      name: 'Requested By',
      selector: (row) => row.requested_by,
      sortable: true,
      filterable: true,
      filterSelector: 'requested_by',
      width: '150px'
    })
    .addColumn({
      name: 'Branch',
      selector: (row) => row.requested_branch,
      sortable: true,
      filterable: true,
      filterSelector: 'requested_branch',
      width: '75px'
    })
    .addColumn({
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      filterable: true,
      filterSelector: 'status',
      width: '75px'
    })
    .addColumn({
      name: 'Request Date',
      selector: (row) => row.createdAt,
      type: 'datetime',
      sortable: true
    })
    .addColumn({
      name: 'Report',
      type: 'link',
      label: 'View Report',
      path: '/lab-report',
      getState: (row) => ({
        id: row.id,         // The request id
        patient: patient    // The patient object
      }),
      visible: (row) => hasPermission('/lab-report', 'View') && row.status === 'complete',
      width: '100px'
    })
    .addColumn({
      name: 'Lab Actions',
      type: 'link',
      label: 'Input Results',
      path: '/lab-request-details',
      getState: (row) => ({ request: row }),
      visible: (row) => hasPermission(row.request_type === 'Radiology' ? '/lab-request-detail-radiology' : '/lab-request-detail', 'View') && (row.request_type === 'Radiology' || row.Patient.patient_type === 'Company' || (row.Patient.patient_type === 'Private' && (row.status === 'paid' || row.status === 'complete'))),
      width: '100px'
    });

  return (
    <div className="patient-list-container">
      {patient && hasPermission('/lab-request-form', 'Add') && (
        <div className="lab-request-link">
          <Link to="/lab-request-form" state={{ patient }}>
            Create Lab Request
          </Link>
        </div>
      )}
      <DataList apiConfig={apiConfig} columns={apiConfig.columns} />
    </div>
  );
};

export default LabTestRequestList;
